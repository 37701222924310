<template>
	<div class="container">
		<el-form label-width="150px" ref="createAttribute" :model="form" size="small" :rules="rulesForm" :validate-on-rule-change="false">
			<el-form-item label="属性名" prop="identifier">
				<el-input v-model="form.identifier"></el-input>
			</el-form-item>
			<el-form-item label="属性说明" prop="desc">
				<el-input v-model="form.desc"></el-input>
			</el-form-item>
			<el-form-item label="类型" prop="type">
				<el-select class="m-2" v-model="form.type" placeholder="请选择属性">
					<el-option value="int" label="数字"></el-option>
					<el-option value="enum" label="枚举"></el-option>
					<el-option value="text" label="字符串"></el-option>
					<el-option value="bool" label="布尔值"></el-option>
					<el-option value="array" label="数组"></el-option>
					<el-option value="struct" label="对象"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="是否必须">
				<el-switch v-model="form.required" :active-value="1" :inactive-value="0" size="small" active-text="必须" inactive-text="非必须" />
			</el-form-item>
			<el-form-item label="读写权限">
				<el-switch v-model="form.writable" :active-value="1" :inactive-value="0" size="small" active-text="读写" inactive-text="只读" />
			</el-form-item>
			<!-- <el-divider>
				<i class="el-icon-star-on"></i>
			</el-divider> -->

			<template v-if="form.type == 'int'">
				<el-form-item label="最小值" prop="intRange">
					<el-input-number v-model="int.min"></el-input-number>
				</el-form-item>
				<el-form-item label="最大值" prop="intRange">
					<el-input-number v-model="int.max" :min="int.min+1"></el-input-number>
				</el-form-item>
				<el-form-item label="步长">
					<el-input-number v-model="int.step" :min="1"></el-input-number>
				</el-form-item>
				<el-form-item label="默认值" prop="intDef">
					<el-input-number v-model="int.default" :min="int.min" :max="int.max"></el-input-number>
				</el-form-item>
				<el-form-item label="单位">
					<el-input v-model="int.unit" style="width:130px;" placeholder="无单位时可不填,用于数据展示"></el-input>
				</el-form-item>
			</template>

			<template v-if="form.type == 'enum'">
				<el-form-item label="增加字段" prop="enum">
					<el-input class="inline-row-input" placeholder="请输入值" v-model="enumValue.value"></el-input>
					<el-input class="inline-row-input" placeholder="请输入说明" v-model="enumValue.key"></el-input>
					<el-button type="success" @click="addEnumValue(enumValue.values,enumValue.value,enumValue.key)">增加</el-button>
				</el-form-item>
				<el-form-item v-for="(name,value,inedx) in enumValue.values" :key="inedx" :label='name+" 的值为"'>
					<el-tag class="inline-row-tag" type="success">{{value}}</el-tag>
					<el-button type="danger" @click="delEnumValue(enumValue.values,value)">删除</el-button>
				</el-form-item>
				<el-form-item label="默认值" prop="enumDef">
					<el-input v-model="enumValue.default"></el-input>
				</el-form-item>
			</template>

			<template v-if="form.type == 'text'">
				<el-form-item label="最大长度" prop="length">
					<el-input-number v-model="string.length" :min="1"></el-input-number>
				</el-form-item>
				<el-form-item label="默认值" prop="stringDef">
					<el-input v-model="string.default"></el-input>
				</el-form-item>
			</template>

			<template v-if="form.type == 'bool'">
				<el-form-item label="为真(说明)" prop="bool_true">
					<el-input v-model="bool.true" placeholder="数据为真时的描述"></el-input>
				</el-form-item>
				<el-form-item label="为假(说明)" prop="bool_false">
					<el-input v-model="bool.false" placeholder="数据为假时的描述"></el-input>
				</el-form-item>
				<el-form-item label="默认值">
					<el-switch v-model="bool.default" :active-text="bool.true" :inactive-text="bool.false" active-value="1" inactive-value="0"></el-switch>
				</el-form-item>
			</template>

			<template v-if="form.type == 'array'">
				<el-form-item label="最大长度">
					<el-input-number :min="1" v-model="array.size"></el-input-number>
				</el-form-item>
				<el-form-item label="增加字段" prop="createFileds">
					<el-row class="mb-4">
						<el-button @click="openAddFields">添加字段</el-button>
					</el-row>
				</el-form-item>
				<el-form-item label="已有字段" v-if="array.item.specs.length > 0">
					<el-table :data="array.item.specs" border size="small">
						<el-table-column prop="identifier" label="Identifier" width="180" />
						<el-table-column prop="name" label="Name" width="180"></el-table-column>
						<el-table-column prop="dataType" width="180" label="data Type">
							<template #default="scope">
								{{scope.row.dataType.type}}
							</template>
						</el-table-column>
						<el-table-column prop="specs" label="specs">
							<template #default="scope">
								<template v-if="scope.row.dataType.type == 'int'">
									<el-tag class="table-column-tag" effect="plain" type="info" size="small">最大值：<span style="color: red;">{{scope.row.dataType.specs.max}}</span></el-tag>
									<el-tag class="table-column-tag" effect="plain" type="info" size="small">最小值：<span style="color: red;">{{scope.row.dataType.specs.min}}</span></el-tag>
									<el-tag class="table-column-tag" effect="plain" type="info" size="small">步长：<span style="color: red;">{{scope.row.dataType.specs.step}}</span></el-tag>
									<el-tag class="table-column-tag" effect="plain" type="info" size="small">单位：<span style="color: red;">{{scope.row.dataType.specs.unit}}</span></el-tag>
								</template>

								<template v-if="scope.row.dataType.type == 'text'">
									<el-tag class="table-column-tag" effect="plain" type="info" size="small">最大长度为：<span style="color: red;">{{scope.row.dataType.specs.length}}</span></el-tag>
								</template>

								<template v-if="scope.row.dataType.type == 'bool'">
									<el-tag class="table-column-tag" effect="plain" type="info" v-for="(name,value,inedx) in scope.row.dataType.specs" :key="inedx" size="small">
										{{name}}：<span style="color: red;">{{value}}</span>
									</el-tag>

								</template>

								<template v-if="scope.row.dataType.type == 'enum'">
									<el-tag class="table-column-tag" effect="plain" type="info" v-for="(name,value,inedx) in scope.row.dataType.specs" :key="inedx" size="small">
										{{name}}：<span style="color: red;">{{value}}</span>
									</el-tag>
								</template>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="220">
							<template #default="scope">
								<el-button type="info" size="small" @click="showRuleDetails(scope.row.dataType.type,scope.row.dataType.specs)">点击查看</el-button>
								<el-button type="info" size="small" @click="deleteSpecs(scope.$index)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
				<el-form-item label="默认值" prop="arrayDef">
					<el-input type="textarea" v-model="array.default"></el-input>
				</el-form-item>
			</template>

			<template v-if="form.type == 'struct'">
				<el-form-item label="增加字段" prop="createFileds">
					<el-row class="mb-4">
						<el-button @click="openAddFields">添加字段</el-button>
					</el-row>
				</el-form-item>
				<el-form-item label="已有字段" v-if="struct.specs.length > 0">
					<el-table :data="struct.specs" border>
						<el-table-column prop="identifier" label="Identifier" width="180" />
						<el-table-column prop="name" label="Name" width="180"></el-table-column>
						<el-table-column prop="dataType" label="data Type" width="180">
							<template #default="scope">
								{{scope.row.dataType.type}}
							</template>
						</el-table-column>
						<el-table-column prop="specs" label="specs">
							<template #default="scope">
								<template v-if="scope.row.dataType.type == 'int'">
									<el-tag class="table-column-tag" effect="plain" type="info" size="small">最大值：<span style="color: red;">{{scope.row.dataType.specs.max}}</span></el-tag>
									<el-tag class="table-column-tag" effect="plain" type="info" size="small">最小值：<span style="color: red;">{{scope.row.dataType.specs.min}}</span></el-tag>
									<el-tag class="table-column-tag" effect="plain" type="info" size="small">步长：<span style="color: red;">{{scope.row.dataType.specs.step}}</span></el-tag>
									<el-tag class="table-column-tag" effect="plain" type="info" size="small">单位：<span style="color: red;">{{scope.row.dataType.specs.unit}}</span></el-tag>
								</template>

								<template v-if="scope.row.dataType.type == 'text'">
									<el-tag class="table-column-tag" effect="plain" type="info" size="small">最大长度为：<span style="color: red;">{{scope.row.dataType.specs.length}}</span></el-tag>
								</template>

								<template v-if="scope.row.dataType.type == 'bool'">
									<el-tag class="table-column-tag" effect="plain" type="info" v-for="(name,value,inedx) in scope.row.dataType.specs" :key="inedx" size="small">
										{{name}}：<span style="color: red;">{{value}}</span>
									</el-tag>

								</template>

								<template v-if="scope.row.dataType.type == 'enum'">
									<el-tag class="table-column-tag" effect="plain" type="info" v-for="(name,value,inedx) in scope.row.dataType.specs" :key="inedx" size="small">
										{{name}}：<span style="color: red;">{{value}}</span>
									</el-tag>
								</template>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="220">
							<template #default="scope">
								<el-button type="info" size="small" @click="showRuleDetails(scope.row.dataType.type,scope.row.dataType.specs)">点击查看</el-button>
								<el-button type="info" @click="deleteSpecs(scope.$index)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
				<el-form-item label="默认值" prop="structDef">
					<el-input type="textarea" v-model="struct.default"></el-input>
				</el-form-item>
			</template>
			<el-form-item>
				<el-button type="primary" @click="submitForm">提交</el-button>
				<el-button plain type="danger" class="cancelBut"><router-link to='/iot/tsl'>取消</router-link></el-button>
			</el-form-item>
		</el-form>
	</div>

	<el-dialog v-model="RuleDetailsVisible">
		<template #title>
			<div class="my-header" style="text-align: center;">
				<h4>数据规则</h4>
			</div>
		</template>
		<arrayStructAttr onlyRead v-if="RuleDetailsForm.type == 'array'" :specs="RuleDetailsForm.specs"></arrayStructAttr>
		<structAttr onlyRead v-if="RuleDetailsForm.type == 'struct'" :specs="RuleDetailsForm.specs"></structAttr>
		<intAttr onlyRead v-if="RuleDetailsForm.type == 'int'" :specs="RuleDetailsForm.specs"></intAttr>
		<boolAttr onlyRead v-if="RuleDetailsForm.type == 'bool'" :specs="RuleDetailsForm.specs"></boolAttr>
		<textAttr onlyRead v-if="RuleDetailsForm.type == 'text'" :specs="RuleDetailsForm.specs"></textAttr>
		<enumAttr onlyRead v-if="RuleDetailsForm.type == 'enum'" :specs="RuleDetailsForm.specs"></enumAttr>
	</el-dialog>

	<el-drawer v-model="addFields.show" size="40%" :close-on-click-modal="false" :title="'添加 '+addFields.type+' 字段'">
		<el-form label-width="110px" label-position="left">
			<el-form-item label="字段名">
				<el-input v-model="addFields.identifier"></el-input>
			</el-form-item>
			<el-form-item label="描述">
				<el-input v-model="addFields.name"></el-input>
			</el-form-item>

			<el-form-item label="类型">
				<el-select class="m-2" v-model="addFields.type" placeholder="请选择属性">
					<el-option value="int" label="数字"></el-option>
					<el-option value="enum" label="枚举"></el-option>
					<el-option value="text" label="字符串"></el-option>
					<el-option value="bool" label="布尔值"></el-option>
				</el-select>
			</el-form-item>

			<template v-if="addFields.type == 'int'">
				<el-form-item label="最小值" prop="min">
					<el-input-number v-model="addFields.int.min"></el-input-number>
				</el-form-item>
				<el-form-item label="最大值" prop="max">
					<el-input-number v-model="addFields.int.max"></el-input-number>
				</el-form-item>
				<el-form-item label="步长" prop="step">
					<el-input-number v-model="addFields.int.step" :min="1"></el-input-number>
				</el-form-item>
				<el-form-item label="单位">
					<el-input v-model="addFields.int.unit"></el-input>
				</el-form-item>
			</template>

			<template v-if="addFields.type == 'enum'">
				<el-form-item label="增加字段">
					<el-input class="inline-row-input" placeholder="请输入值" v-model="addFields.enumValue.value"></el-input>
					<el-input class="inline-row-input" placeholder="请输入说明" v-model="addFields.enumValue.key"></el-input>
					<el-button type="success" @click="addEnumValue(addFields.enumValue.values,addFields.enumValue.value,addFields.enumValue.key)">增加</el-button>
				</el-form-item>
				<el-form-item v-for="(name,value,inedx) in addFields.enumValue.values" :key="inedx" :label='name+" 的值为"'>
					<el-tag class="inline-row-tag" type="success">{{value}}</el-tag>
					<el-button type="danger" @click="delEnumValue(addFields.enumValue.values,value)">删除</el-button>
				</el-form-item>
			</template>


			<template v-if="addFields.type == 'text'">
				<el-form-item label="长度" prop="length">
					<el-input-number v-model="addFields.string.length"></el-input-number>
				</el-form-item>
			</template>

			<template v-if="addFields.type == 'bool'">
				<el-form-item label="为真(说明)" prop="required">
					<el-input v-model="bool.false" placeholder="数据为真时的描述"></el-input>
				</el-form-item>
				<el-form-item label="为假(说明)" prop="required">
					<el-input v-model="bool.true" placeholder="数据为假时的描述"></el-input>
				</el-form-item>
			</template>

		</el-form>

		<el-form-item>
			<el-button type="info" @click="addFields.show = false">关闭</el-button>
			<el-button type="primary" @click="submitAddField">提交</el-button>
		</el-form-item>

	</el-drawer>

</template>

<script>
	import intAttr from '../../../components/tsl/attribute/int.vue'
	import boolAttr from '../../../components/tsl/attribute/bool.vue'
	import enumAttr from '../../../components/tsl/attribute/enum.vue'
	import textAttr from '../../../components/tsl/attribute/text.vue'
	import structAttr from '../../../components/tsl/attribute/struct.vue'
	import arrayIntAttr from '../../../components/tsl/attribute/array_int.vue'
	import arrayTextAttr from '../../../components/tsl/attribute/array_text.vue'
	import arrayStructAttr from '../../../components/tsl/attribute/array_struct.vue'
	export default {
		components: {
			intAttr,
			boolAttr,
			enumAttr,
			textAttr,
			structAttr,
			arrayIntAttr,
			arrayTextAttr,
			arrayStructAttr
		},
		name: 'AddTslAttributes',
		mixins: [],
		data() {
			return {
				form: {
					identifier: "",
					desc: "",
					required: 1,
					writable: 1,
					type: "",
					default: "",
					specs: {},
				},
				int: {
					min: 0,
					max: 100,
					step: 1,
					default: 0,
					unit: "%"
				},
				enumValue: {
					values: {},
					default: "",
					value: "",
					visible: false,
					key: "",
				},
				bool: {
					false: "关",
					true: "开",
					default: false,
				},
				string: {
					length: 32,
					default: "0.00",
				},
				array: {
					size: 1,
					item: {
						type: "struct",
						specs: [],
					},
					default: "",
				},
				struct: {
					default: "",
					specs: [],
				},
				addFields: {
					show: false,
					type: "",
					identifier: "",
					name: "",
					specs: [],
					int: {
						min: 0,
						max: 0,
						step: 0,
						unit: ""
					},
					bool: {
						false: "",
						true: "",
					},
					string: {
						length: 32,
					},
					enumValue: {
						values: {},
						default: "",
						value: "",
						visible: false,
						key: "",
					}
				},
				RuleDetailsVisible: false,
				RuleDetailsForm: {},
				rulesForm: {
					identifier: [{
						required: true,
						message: '属性字段',
						trigger: "change"
					}],
					name: [{
						required: true,
						message: '请输入属性描述',
						trigger: "change"
					}],
					bool_true: [{
						validator: this.valiFormBoolTrue,
						trigger: "change"
					}],
					bool_false: [{
						validator: this.valiFormBoolFalse,
						trigger: "change"
					}],
					required: [{
						required: true,
						message: '字段必填',
						trigger: "change"
					}],
					type: [{
						required: true,
						message: '请选择类型',
						trigger: "change"
					}],
					enum: [{
						validator: this.valiFormEnum,
						trigger: 'change'
					}],
					enumDef: [{
						validator: this.valiFormEnumDefault,
						trigger: 'change'
					}],
					intRange: [{
						validator: this.valiFormInt,
						trigger: 'change'
					}],
					intDef: [{
						validator: this.valiFormIntDef,
						trigger: 'change'
					}],
					stringDef: [{
						validator: this.valiFormStringDef,
						trigger: 'change'
					}],
					createFileds: [{
						validator: this.valiFileds,
						trigger: 'change'
					}],
					arrayDef: [{
						validator: this.valiArrayDef,
						trigger: 'change'
					}],
					structDef: [{
						validator: this.valiStructDef,
						trigger: 'change'
					}],
				}
			}
		},
		methods: {
			valiFormEnum: function(rule, value, callback) {
				if (Object.keys(this.enumValue.values).length == 0) {
					callback(new Error("枚举对象不能为空"));
					return;
				}
				callback();
			},
			valiFormEnumDefault: function(rule, value, callback) {
				if (!this.validateEnum(this.enumValue)) {
					callback(new Error("值不在集合中"));
					return;
				}
				callback();
			},
			valiFormInt: function(rule, value, callback) {
				if (this.int.max <= this.int.min) {
					callback(new Error("值范围错误"));
					return;
				}
				callback();
			},
			valiFormIntDef: function(rule, value, callback) {
				if (!this.validateInt(this.int)) {
					callback(new Error("默认值不在范围中"));
					return;
				}
				callback();
			},
			valiFormBoolFalse: function(rule, value, callback) {
				if (!this.bool.false) {
					callback(new Error("参数为必填"));
					return;
				}
				callback();
			},
			valiFormBoolTrue: function(rule, value, callback) {
				if (!this.bool.true) {
					callback(new Error("参数为必填"));
					return;
				}
				callback();
			},
			valiFormStringDef: function(rule, value, callback) {
				if (!this.validateString(this.string)) {
					callback(new Error("默认值长度超出"));
					return;
				}
				callback();
			},
			valiFileds: function(rule, value, callback) {
				let fileds = this.struct.specs;
				if (this.form.type == "array") {
					fileds = this.array.item.specs;
				}

				if (fileds.length == 0) {
					callback(new Error("必须添加字段"));
					return;
				}
				callback();
			},
			valiArrayDef: function(rule, value, callback) {
				if (!this.array.default) {
					callback(new Error("默认值必须为数组"));
					return;
				}
				let defArr;
				try {
					defArr = JSON.parse(this.array.default);
				} catch (e) {
					callback(new Error("默认值数据格式错误"));
					return;
				}

				if (!(defArr instanceof Array)) {
					callback(new Error("默认值必须为数组"));
					return;
				}


				if (defArr.length > this.array.size) {
					callback(new Error("数组最大容量为：" + this.array.size));
					return;
				}

				for (let index in defArr) {
					for (let key in defArr[index]) {

						let item = this.inArraySpecs(key, this.array.item.specs)
						if (!item) {
							callback(new Error("默认值字段`" + key + "`不在设定的范围中"));
							return;
						}
						// 检查值是否正确
						if (!this.checkStructValue(defArr[index][key], item.dataType.type, item.dataType.specs)) {
							callback(new Error("字段`" + key + "`值内容错误"));
							return;
						}
					}
				}
				callback();
			},
			valiStructDef: function(rule, value, callback) {
				if (!this.struct.default) {
					callback(new Error("默认值类型必须为对象"));
					return;
				}
				let defDef;
				try {
					defDef = JSON.parse(this.struct.default);
				} catch (e) {
					callback(new Error("默认值数据格式错误"));
					return;
				}

				for (let key in defDef) {

					let item = this.inArraySpecs(key, this.struct.specs)
					if (!item) {
						callback(new Error("默认值字段`" + key + "`不在设定的范围中"));
						return;
					}
					// 检查值是否正确
					if (!this.checkStructValue(defDef[key], item.dataType.type, item.dataType.specs)) {
						callback(new Error("字段`" + key + "`值内容错误"));
						return;
					}
				}
				callback();
			},
			inArraySpecs: function(key, specs) {
				for (const index in specs) {
					if (specs[index].identifier == key) {
						return specs[index];
					}
				}
				return false;
			},
			checkStructValue: function(val, dataType, specs) {
				if (dataType == "int") {
					return typeof val == "number" && val <= specs.max && val >= specs.min;
				}

				if (dataType == "text") {

					console.info()
					return typeof val == "string" && specs.length >= val.length
				}

				if (dataType == "enum") {
					for (let key in specs) {
						if (val == key) {
							return true;
						}
					}
					return false;
				}

				if (dataType == "bool") {
					return val == 0 || val == 1;
				}

				return false;
			},
			validateDefault: function() {
				var func;
				var obj;
				switch (this.form.type) {
					case "int":
						func = this.validateInt;
						obj = this.int;
						break;
					case "enum":
						func = this.validateEnum;
						obj = this.enumValue;
						break;
					case "text":
						func = this.validateString;
						obj = this.string;
						break;
					case "array":
						func = this.validateArray;
						obj = this.array;
						break;
					case "struct":
						func = this.validateStruct;
						obj = this.struct;
						break;
					case "bool":
						this.form.default = this.bool.default;
						return;
					default:
						this.$message.error("数据类型错误");
						return;
				}
				if (!func(obj)) {
					this.$message.error("默认值错误");
					return;
				}
				this.form.default = obj.default;
			},
			validateInt: function(obj) {
				if (obj.default !== undefined && obj.max !== undefined && obj.min !== undefined) {
					return obj.default <= obj.max && obj.default >= obj.min;
				}
				return false;
			},
			validateEnum: function(obj) {
				if (obj.default !== undefined && obj.values !== undefined) {
					return obj.values.hasOwnProperty(obj.default)
				}
				return false;
			},
			validateString: function(obj) {
				if (obj.default !== undefined && obj.length !== undefined) {
					return obj.length >= obj.default.length;
				}
				return false;
			},
			openAddFields: function() {
                this.addFields= {
					show: false,
					type: "",
					identifier: "",
					name: "",
					specs: [],
					int: {
						min: 0,
						max: 0,
						step: 0,
						unit: ""
					},
					bool: {
						false: "",
						true: "",
					},
					string: {
						length: 32,
					},
					enumValue: {
						values: {},
						default: "",
						value: "",
						visible: false,
						key: "",
					}
				}
				this.addFields.show = true;
			},

			showRuleDetails: function(type, specs) {
				this.RuleDetailsForm = {
					type: type,
					specs: specs
				};
				this.RuleDetailsVisible = true;
			},
			addEnumValue: function(obj, key, value) {
				if (key && value) {
					obj[key] = value;
				}
				value = "";
				key = "";
			},
			delEnumValue: function(obj, key) {
				delete obj[key];
			},
			submitAddField: function() {
				let fields = this.struct.specs;
				if (this.form.type == "array") {
					fields = this.array.item.specs;
				}

				for (const field of fields) {
					if (field.identifier == this.addFields.identifier) {
						this.$message.error("字段已经存在");
						return;
					}
				}

				let specs;
				if (this.addFields.type == "int") {
					specs = this.addFields.int;
				}

				if (this.addFields.type == "text") {
					specs = this.addFields.string;
				}

				if (this.addFields.type == "enum") {
					specs = this.addFields.enumValue.values;
				}

				if (this.form.type == "struct") {
					this.struct.specs.push({
						identifier: this.addFields.identifier,
						name: this.addFields.name,
						dataType: {
							type: this.addFields.type,
							specs: specs
						}
					})
				}

				if (this.form.type == "array") {
					this.array.item.specs.push({
						identifier: this.addFields.identifier,
						name: this.addFields.name,
						dataType: {
							type: this.addFields.type,
							specs: specs
						}
					})
				}
                this.addFields.show = false
			},
			deleteSpecs: function(index) {
				if (this.form.type == "struct") {
					this.struct.specs.splice(index, 1);
				}

				if (this.form.type == "array") {
					this.array.item.specs.splice(index, 1);
				}
			},
			submitForm: function() {
				this.$refs["createAttribute"].validate((valid) => {
					if (!valid) {
						return;
					}

					if (this.form.type == "int") {
						this.form.specs = this.int;
						this.form.default = this.int.default;
					}

					if (this.form.type == "text") {
						this.form.specs = this.string;
						this.form.default = this.string.default;
					}

					if (this.form.type == "enum") {
						this.form.specs = this.enumValue.values;
						this.form.default = this.enumValue.default;
					}

					if (this.form.type == "bool") {
						this.form.specs = {
							"0": this.bool.false,
							"1": this.bool.true
						};
						this.form.default = this.bool.default;
					}

					if (this.form.type == "array") {
						this.form.specs = this.array;
						this.form.default = JSON.parse(this.array.default);
					}

					if (this.form.type == "struct") {
						this.form.specs = this.struct.specs;
						this.form.default = JSON.parse(this.struct.default);
					}

					this.api.TslAttributeCreate(this.form).then(res => {
						if (res.data.code == 200) {
							this.$message.success("成功了")
							return;
						}
						this.$message.error(res.data.msg)
					});
				});

			},
			//generate
		},
	}
</script>

<style>
	.mx-1 {
		margin: 5px;
	}

	.el-input.ml-1.w-20 {
		width: 100px
	}

	.inline-row-tag {
		margin: 0 5px;
	}

	.inline-row-input {
		width: 300px;
		margin: 0 5px;
	}

	.el-drawer__body {
		padding: 15px;
		overflow: auto;
	}

	.table-column-tag {
		margin: 0 5px;
	}

	.el-button--danger.is-plain.cancelBut {
		background: #F56C6C;
	}
</style>